import { SportType } from '../../__generated__/graphql';
import UI from '../UI';

const sportIconMap = {
  running: <UI.Icons.Running />,
  trail_running: <UI.Icons.TrailRunning />,
  cycling: <UI.Icons.Cycling />,
  gravel_cycling: <UI.Icons.GravelCycling />,
  mountainbiking: <UI.Icons.Mountainbiking />,
  cyclocross: <UI.Icons.Cyclocross />,
  athletics: <UI.Icons.Athletics />,
  biathlon: <UI.Icons.Biathlon />,
  climbing: <UI.Icons.Climbing />,
  combat_sport: <UI.Icons.CombatSport />,
  duathlon: <UI.Icons.Duathlon />,
  fishing: <UI.Icons.Fishing />,
  fitness: <UI.Icons.Fitness />,
  football: <UI.Icons.Football />,
  golf: <UI.Icons.Golf />,
  handball: <UI.Icons.Handball />,
  hockey: <UI.Icons.Hockey />,
  horse_riding: <UI.Icons.HorseRiding />,
  ice_skating: <UI.Icons.IceSkating />,
  kitesurfing: <UI.Icons.Kitesurfing />,
  obstacle_run: <UI.Icons.ObstacleRun />,
  rowing: <UI.Icons.Rowing />,
  sailing: <UI.Icons.Sailing />,
  skiing: <UI.Icons.Skiing />,
  surfing: <UI.Icons.Surfing />,
  swimming: <UI.Icons.Swimming />,
  tennis: <UI.Icons.Tennis />,
  triathlon: <UI.Icons.Triathlon />,
  volleyball: <UI.Icons.Volleyball />,
  walking: <UI.Icons.Walking />,
  yoga: <UI.Icons.Yoga />,
  other: <UI.Icons.Other />,
};

export default ({ type }: { type: SportType | null; }) => sportIconMap[type || 'other'];
